import { API, Logger } from "aws-amplify";
import {
  admCreateMiniWeb,
  admUpdateMiniWebSearchKey,
  updateMiniWeb,
  deleteMiniWeb,
} from "@/graphql/mutations";
import { getServiceProvider } from "@/graphql/queries";
import { getMiniWeb } from "@/graphql/kdwqueries";
import { getUserSub } from "@/services/User";
// import { TEMPLATE_MINIWEB_LIST } from '@/services/miniweb/resolver'

const logger = new Logger("WebAdminController");

export async function createMiniWeb() {
  const miniweb = await API.graphql({
    query: admCreateMiniWeb,
    variables: { params: {} },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  logger.info(`create - miniweb is ${JSON.stringify(miniweb)}.`);
  // const data = JSON.parse(miniweb.data.admCreateMiniWeb)
  // if (data.statusCode !== 200) {
  //    throw new Error(JSON.stringify(data.body))
  // }
  return miniweb;
}

export async function admDeleteMiniWeb() {
  try {
    await API.graphql({
      query: deleteMiniWeb,
      input: { serviceProviderID: await getUserSub() },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function updateMiniWebConfig(params) {
  logger.info(`update - miniweb will be ${JSON.stringify(params)}.`);
  // sanitize input

  for (var key of Object.keys(params)) {
    if (params[key] === null) {
      params[key] = "";
    }
  }
  // update
  const miniweb = await API.graphql({
    query: updateMiniWeb,
    variables: {
      input: {
        serviceProviderID: await getUserSub(),
        ...params,
      },
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  // logger.info(`update - miniweb is ${JSON.stringify(miniweb)}.`)
  return miniweb;
  /*
  name: String               @default(value: "")
  logo: String               @default(value: "")
  address1: String           @default(value: "")
  address2: String           @default(value: "")
  postcode: String           @default(value: "")
  postcodecity: PostalCode   @hasOne
  country: Country           @hasOne
  phone: String              @default(value: "")
  email: String              @default(value: "")
  themeId: String            @default(value: "jean01")
  */
}

export async function updateSearchKey(searchkey) {
  try {
    // console.log('updateSearchKey:', searchkey)
    const miniweb = await API.graphql({
      query: admUpdateMiniWebSearchKey,
      variables: {
        params: JSON.stringify({
          searchkey,
        }),
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    // logger.info(`update searchkey - miniweb is ${JSON.stringify(miniweb)}.`)
    return miniweb;
  } catch (err) {
    console.log("updateSearchKey - failed");
    console.log(err);
    return {};
  }
}

export async function getMiniWebConfig() {
  const userSub = await getUserSub();
  console.log(`userSub is ${userSub}.`);
  const miniweb = await API.graphql({
    query: getMiniWeb,
    variables: {
      serviceProviderID: userSub,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  // logger.info(`get - miniweb is ${JSON.stringify(miniweb)}.`)
  return miniweb;
  /*
    const data = JSON.parse(miniweb.data.getMiniWeb)
    if (data.statusCode !== 200) {
        throw new Error(JSON.stringify(data.body))
    }
    return miniweb
    */
}

export async function getMiniWebConfigFromSP() {
  const userSub = await getUserSub();
  // console.log(`userSub is ${userSub}.`)
  const spData = await API.graphql({
    query: getServiceProvider,
    variables: {
      id: userSub,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  // logger.info(`get - SP is ${JSON.stringify(spData)}.`)
  return spData.data.getServiceProvider;
  /*
    const data = JSON.parse(miniweb.data.getMiniWeb)
    if (data.statusCode !== 200) {
        throw new Error(JSON.stringify(data.body))
    }
    return miniweb
    */
}
