<template>
  <div>
    <base-section id="web-admin">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <base-info-card :title="$t('sideMenuSP.miniweb')" />
          </v-col>
        </v-row>
        <kdw-dialog-general-info-delete-confirm
          v-model="dialogDelete"
          type="Delete"
          user-text-tag="miniweb.deleteMiniWebRequest"
          @dialogGeneralDeleteConfirm="deleteMiniWebConfirmed($event)"
        />
        <v-row class="align-start" no-gutters>
          <!-- This is the top to check if we need to create/delete the page-->
          <kdw-btn
            v-if="!displayMiniwebCreation"
            class="ma-2"
            dense
            @click="deleteMiniWeb()"
          >
            {{ $t("miniweb.deleteMiniWebButton") }}
          </kdw-btn>
          <kdw-btn
            v-if="displayMiniwebCreation"
            class="ma-2"
            dense
            @click="createMiniWeb()"
          >
            {{ $t("miniweb.CreateMiniWebButton") }}
          </kdw-btn>
          <v-spacer />
          <!-- Choose the template list -->
          <v-select
            v-if="!displayMiniwebCreation"
            v-model="myServiceProvider.layoutId"
            class="ma-2"
            :items="miniWebTemplateManager.templateList()"
            item-text="name"
            item-value="id"
            :label="$t('miniweb.pickTemplate')"
            dense
            outlined
            @change="templateChange()"
          >
            <template slot="item" slot-scope="data">
              <v-img
                max-height="86"
                max-width="147"
                :src="require('../../../assets/' + data.item.logo)"
                class="my-1 mr-3"
              />
              {{ data.item.name }}
            </template>
          </v-select>

          <v-tooltip v-if="!displayMiniwebCreation" bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
            </template>
            <span>{{ $t("miniweb.pickTemplateHint") }}</span>
          </v-tooltip>
          <v-spacer />
          <!-- Choose the color -->
          <v-select
            v-if="!displayMiniwebCreation"
            v-model="myServiceProvider.themeId"
            :items="colorList"
            :label="$t('miniweb.pickColor')"
            item-text="name"
            item-value="hex"
          >
            <template slot="item" slot-scope="data">
              <v-icon :color="data.item.hex">mdi-checkbox-blank</v-icon>
              {{ data.item.name }}
            </template>
          </v-select>
          <v-tooltip v-if="!displayMiniwebCreation" bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
            </template>
            <span>{{ $t("miniweb.pickColorHint") }}</span>
          </v-tooltip>
        </v-row>
        <v-row v-if="!displayMiniwebCreation">
          <span>{{ $t("miniweb.title") }}</span>
        </v-row>
        <v-row v-if="!displayMiniwebCreation">
          <v-col cols="12" md="12">
            <!-- Management of the COMPANY DATA -->
            <v-card
              v-if="
                !displayMiniwebCreation &&
                miniWebTemplateManager.properties.identity.global
              "
              class="rounded-xl mx-auto"
            >
              <kdw-title-card-system-bar
                color="jean"
                icon="mdi-card-account-details-outline"
                title-tag="miniweb.companyParam"
              />
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <v-row justify="start" align-content="space-around">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            dense
                            plain
                            outlined
                            color="jean"
                            min-width="144"
                            class="font-weight-bold my-2 ml-8"
                            rounded
                            v-on="on"
                            @click="copyCpyData"
                          >
                            {{ $t("button.copyCpy") }}
                            <v-icon> mdi-content-copy </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("button.copyCpy") }}</span>
                      </v-tooltip>
                    </v-row>
                    <v-row>
                      <kdw-text-field
                        v-if="
                          miniWebTemplateManager.properties.identity.companyName
                        "
                        id="identity_companyname"
                        v-model="myServiceProvider.namecpy"
                        :label="$t('identity.companyname') + $t('meta.star')"
                        prepend-icon="mdi-account-box"
                        clearable
                      />
                    </v-row>
                    <v-row>
                      <kdw-text-field
                        id="identity_firstname"
                        v-model="myServiceProvider.firstname"
                        :label="$t('identity.firstname')"
                        prepend-icon="mdi-account-box"
                        clearable
                      />
                    </v-row>
                    <v-row>
                      <v-checkbox
                        v-if="miniWebTemplateManager.properties.identity.siret"
                        id="identity_siret_check"
                        v-model="myServiceProvider.siretDisplay"
                        dense
                      />
                      <kdw-text-field
                        v-if="miniWebTemplateManager.properties.identity.siret"
                        id="identity_siret"
                        v-model="myServiceProvider.siret"
                        :label="$t('identity.siret') + $t('meta.star')"
                        prepend-icon="mdi-account-box"
                        clearable
                        :disabled="!myServiceProvider.siretDisplay"
                      />
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <kdw-picture-selector
                      v-if="miniWebTemplateManager.properties.identity.logo"
                      v-model="myServiceProvider.logo"
                      nature="Logo"
                      :display="trickUpdatePicture"
                      :image-name-complement="imageNameComplement"
                      :file-input-hint="$t('pictureSelector.hint')"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-checkbox
                    v-if="miniWebTemplateManager.properties.identity.address"
                    v-model="myServiceProvider.addresscpyDisplay"
                    dense
                  />
                  <kdw-address
                    v-if="miniWebTemplateManager.properties.identity.address"
                    v-model="myServiceProvider.addresscpy"
                    :disabled="!myServiceProvider.addresscpyDisplay"
                    :display="trickUpdateAddress"
                  />
                </v-row>
                <v-row no-gutters>
                  <v-col cols="2" sm="1" class="d-flex justify-end">
                    <v-checkbox
                      v-if="miniWebTemplateManager.properties.contact.phone"
                      v-model="myServiceProvider.contact.phonecpyDisplay"
                      dense
                    />
                  </v-col>
                  <v-col cols="10" sm="3">
                    <kdw-vue-tel-input
                      v-model="myServiceProvider.contact.phonecpy"
                      select-id="IDcpyphone"
                      :vif="
                        miniWebTemplateManager.properties.contact.phone
                          ? true
                          : false
                      "
                      :disabled="!myServiceProvider.contact.phonecpyDisplay"
                      @internationalPhoneNumber="
                        internationalPhoneNumberFct($event)
                      "
                    />
                  </v-col>
                  <v-col cols="2" sm="1" class="d-flex justify-end">
                    <v-checkbox
                      v-if="miniWebTemplateManager.properties.contact.email"
                      id="IDcpymailCheck"
                      v-model="myServiceProvider.contact.emailcpyDisplay"
                      dense
                    />
                  </v-col>
                  <v-col cols="10" sm="7">
                    <kdw-text-field-email
                      v-if="miniWebTemplateManager.properties.contact.email"
                      id="IDcpymail"
                      v-model="myServiceProvider.contact.emailcpy"
                      :disabled="!myServiceProvider.contact.emailcpyDisplay"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <kdw-btn @click="updateMiniWebCompanyParameters">
                  {{ $t("button.validateQuestion") }}
                </kdw-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!--  NEW SECTION WEB SITE PARAMETERS -->
        <v-row v-if="!displayMiniwebCreation">
          <v-col cols="12" md="12">
            <v-card class="rounded-xl mx-auto">
              <kdw-title-card-system-bar
                color="jean"
                icon="mdi-web"
                title-tag="miniweb.webParam"
              />
              <v-card-text>
                <v-row no-gutters class="d-flex align-center">
                  <v-icon>mdi-web</v-icon>
                  <span class="text-h6"
                    >https://KidiwiServices.com/en/web/</span
                  >
                  <kdw-text-field
                    id="webname"
                    v-model="myServiceProvider.searchkey"
                    :label="$t('miniweb.yourSiteName')"
                    :hint="$t('miniweb.yourSiteNameHint')"
                    clearable
                    dense
                  />
                  <!--:value="myServiceProvider.searchkey"-->
                  <vue-qrcode
                    v-if="myServiceProvider.searchkey !== ''"
                    :value="qrcodeValue"
                  />
                  <kdw-btn
                    v-if="myServiceProvider.searchkey !== ''"
                    class="ml-2"
                    target="_blank"
                    color="jean"
                    :href="qrcodeValue"
                  >
                    {{ $t("miniweb.openSite") }}
                  </kdw-btn>
                </v-row>
                <v-row no-gutters>
                  <kdw-text-field
                    id="keywords"
                    v-model="keyword"
                    :label="$t('miniweb.keyword')"
                    :hint="$t('miniweb.keywordHint')"
                    prepend-icon="mdi-search-web"
                    clearable
                    dense
                  />
                  <kdw-btn text @click="addKeyword()">
                    {{ $t("miniweb.addKeyword") }}
                  </kdw-btn>
                </v-row>
                <v-row>
                  <v-chip-group
                    v-for="(keyword, i) in myServiceProvider.keywords"
                    :key="'row' + i"
                    active-class="deep-purple accent-4 white--text"
                    column
                  >
                    <v-chip
                      class="ma-2"
                      close
                      color="red"
                      text-color="white"
                      @click:close="deleteKeyword(i)"
                    >
                      {{ keyword }}
                    </v-chip>
                  </v-chip-group>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <kdw-btn @click="updateMiniWebCompanyParameters">
                  {{ $t("button.validateQuestion") }}
                </kdw-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!--  NEW SECTION SHOWROOM PARAMETERS -->
        <v-row v-if="!displayMiniwebCreation">
          <v-col cols="12" md="12">
            <v-card class="rounded-xl mx-auto">
              <kdw-title-card-system-bar
                color="jean"
                icon="mdi-webhook"
                title-tag="miniweb.showroomParam"
              />
              <v-card-text>
                <v-row>
                  <kdw-text-field
                    id="JobName"
                    v-model="myServiceProvider.showroom.jobName"
                    :label="$t('miniweb.showroomJobName')"
                    prepend-icon="mdi-alarm-light"
                    clearable
                    dense
                    class="mt-5"
                  />
                </v-row>
                <v-row>
                  {{ $t("miniweb.showroomDescription") }} ({{
                    $t("miniweb.showroomDescriptionHint")
                  }}):
                  <el-tiptap
                    id="showroomDescription"
                    v-model="myServiceProvider.showroom.description"
                    class="mb-5"
                    :spellcheck="true"
                    :lang="languageEditor"
                    :char-counter-count="1 === 0"
                    :extensions="extensions"
                  />
                </v-row>
                <v-row v-if="debug">
                  {{ myServiceProvider.showroom }}
                </v-row>
                <v-row>
                  <v-checkbox
                    id="showroom_priceRange_check"
                    v-model="myServiceProvider.showroom.priceRangeDisplay"
                    dense
                  />
                  <kdw-text-field
                    id="priceRange"
                    v-model="myServiceProvider.showroom.priceRange"
                    :label="$t('miniweb.showroomPriceRange')"
                    hint="35€-150€"
                    prepend-icon="mdi-currency-eur"
                    clearable
                    dense
                    :disabled="!myServiceProvider.showroom.priceRangeDisplay"
                  />
                </v-row>
                <v-row>
                  <kdw-text-field
                    id="score"
                    v-model="myServiceProvider.showroom.score"
                    :label="$t('miniweb.showroomScore')"
                    hint="4.5"
                    prepend-icon="mdi-star"
                    clearable
                    dense
                  />
                </v-row>
                <v-row>
                  <v-col cols="3" sm="3">
                    {{ $t("miniweb.showroomBackgroundPicture") }}
                  </v-col>
                  <v-col cols="6" sm="6">
                    <kdw-picture-selector
                      v-model="myServiceProvider.showroom.background"
                      nature="backgroundPicture"
                      :display="trickUpdatePicture"
                      :image-name-complement="
                        imageNameComplementBackgroundShowroom
                      "
                      picture-selector-id="logo_file_input_displaymainBg"
                      :file-input-hint="$t('pictureSelector.hint')"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  {{ $t("miniweb.showroomPictures") }} <br />
                  <v-checkbox
                    id="showroom_picturesLoop_check"
                    v-model="myServiceProvider.showroom.picturesLoop"
                    :label="$t('miniweb.showroomPicturesLoop')"
                    dense
                  />
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4">
                    <kdw-picture-selector
                      v-model="myServiceProvider.showroom.displayPicture1"
                      nature="Picture"
                      :display="trickUpdatePicture"
                      :image-name-complement="imageNameComplementInsideShowroom"
                      picture-selector-id="logo_file_input_displayPicture1"
                      :file-input-hint="$t('pictureSelector.hint')"
                    />
                  </v-col>
                  <v-col cols="4" sm="4">
                    <kdw-picture-selector
                      v-model="myServiceProvider.showroom.displayPicture2"
                      nature="Picture"
                      :display="trickUpdatePicture"
                      :image-name-complement="imageNameComplementInsideShowroom"
                      picture-selector-id="logo_file_input_displayPicture2"
                      :file-input-hint="$t('pictureSelector.hint')"
                    />
                  </v-col>
                  <v-col cols="4" sm="4">
                    <kdw-picture-selector
                      v-model="myServiceProvider.showroom.displayPicture3"
                      nature="Picture"
                      :display="trickUpdatePicture"
                      :image-name-complement="imageNameComplementInsideShowroom"
                      picture-selector-id="logo_file_input_displayPicture3"
                      :file-input-hint="$t('pictureSelector.hint')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <kdw-btn @click="updateMiniWebCompanyParameters">
                  {{ $t("button.validateQuestion") }}
                </kdw-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!--  NEW SECTION SERVICES PARAMETERS -->
        <v-row v-if="!displayMiniwebCreation">
          <v-col cols="12" md="12">
            <v-card class="rounded-xl mx-auto">
              <kdw-title-card-system-bar
                color="jean"
                icon="mdi-webpack"
                title-tag="miniweb.servicesParam"
              />
              <v-card-text>
                <v-row>
                  {{ $t("miniweb.servicesDescription") }} ({{
                    $t("miniweb.servicesDescriptionHint")
                  }}):
                  <el-tiptap
                    id="servicesDescriptionBack"
                    v-model="myServiceProvider.services.description"
                    :spellcheck="true"
                    :lang="languageEditor"
                    :char-counter-count="1 === 0"
                    :extensions="extensions"
                  />
                </v-row>
                <v-row>
                  <v-col cols="3" sm="3">
                    {{ $t("miniweb.servicesBackgroundPicture") }}
                  </v-col>
                  <v-col cols="6" sm="6">
                    <kdw-picture-selector
                      v-model="myServiceProvider.services.background"
                      nature="Picture"
                      :display="trickUpdatePicture"
                      :image-name-complement="
                        imageNameComplementBackgroundServices
                      "
                      picture-selector-id="logo_file_input_ServiceBg"
                      :file-input-hint="$t('pictureSelector.hint')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <kdw-btn @click="updateMiniWebCompanyParameters">
                  {{ $t("button.validateQuestion") }}
                </kdw-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="debug">
          <v-col>
            miniwebConfig: <br />
            {{ miniwebConfig }}
          </v-col>
        </v-row>
        <v-row v-if="debug">
          <v-col>
            myServiceProvider: <br />
            {{ myServiceProvider }}
          </v-col>
        </v-row>
        <v-row v-if="debug">
          <v-col>
            miniWebTemplateManager: <br />
            {{ miniWebTemplateManager }}
          </v-col>
        </v-row>
        <v-divider />
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import { Logger } from "aws-amplify";
import VueQrcode from "@/components/kdw/QRCode";
import {
  FILEHEADERIMAGENAMECOMPLEMENTMINIWEB,
  FILEHEADERIMAGENAMECOMPLEMENTMINIWEBBACGROUNDSHOWROOM,
  FILEHEADERIMAGENAMECOMPLEMENTMINIWEBINSIDESHOWROOM,
  FILEHEADERIMAGENAMECOMPLEMENTMINIWEBBACGROUNDSERVICES,
} from "@/services/File";
import {
  createMiniWeb as CcreateMiniWeb,
  // admDeleteMiniWeb as CadmDeleteMiniWeb,
  updateMiniWebConfig as CupdateMiniWebConfig,
  updateSearchKey as CupdateSearchKey,
  getMiniWebConfig as CgetMiniWebConfig,
  getMiniWebConfigFromSP as CgetMiniWebConfigFromSP,
} from "@/controllers/WebAdminController";
import { MiniWebTemplateManager } from "@/services/MiniWebTemplate";
import {
  pictureUpdateS3,
  deletePictureS3,
} from "@/helpers/GenericFunctionsS3Download";
import { TEMPLATE_MINIWEB_LIST } from "@/services/miniweb/resolver";
import { getMiniWebConfig } from "@/services/miniweb/service";
import { isObjectEmpty } from "@/helpers/GenericFunctions";
import { getUserSub } from "@/services/User";
import * as DOMPurify from "dompurify";

// Editor: https://github.com/Leecason/element-tiptap
// https://element-tiptap.vercel.app/

import {
  // ElementTiptap,
  ElementTiptapPlugin,
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  CodeBlock,
  Blockquote,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  TextAlign,
  Indent,
  LineHeight,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  FormatClear,
  TextColor,
  TextHighlight,
  Preview,
  FontType,
  FontSize,
} from "element-tiptap";

import ElementUI from "element-ui";
// import ElementUI's styles
import "element-ui/lib/theme-chalk/index.css";
// import this package's styles
import "element-tiptap/lib/index.css";
// use ElementUI's plugin
Vue.use(ElementUI);
// use this package's plugin
Vue.use(ElementTiptapPlugin, {
  // plugin options
});
// Now you register `'el-tiptap'` component globally.

const logger = new Logger("WebAdmin");

@Component({
  name: "WebAdmin",
  components: {
    VueQrcode,
    // 'el-tiptap': ElementTiptap,
  },
})
class WebAdmin extends Vue {
  metaInfo() {
    return {
      title: this.$t("sideMenuSP.miniweb"),
      titleTemplate: (titleChunk) => {
        console.log(`titleChunk is ${titleChunk}`);
        return titleChunk
          ? `${titleChunk} - ${this.$t("meta.titleTemplate")}`
          : `${this.$t("meta.titleTemplate")}`; // : this.$t('meta.titleTemplate')
      },
    };
  }

  debug = false;
  miniwebConfig = {};
  // To check if the miniweb is active or nor
  displayMiniwebCreation = false;

  // Data linked to Service provider
  myServiceProvider = {
    id: "",
    namecpy: "",
    firstname: "",
    logo: {
      originalName: "",
      name: "",
      image: null,
    },
    siretDisplay: true,
    siret: "",
    addresscpyDisplay: true,
    addresscpy: {
      address1: "",
      address2: "",
      postcode: "",
      cityId: "",
      countryId: "",
    },
    contact: {
      phonecpyDisplay: true,
      phonecpy: "",
      phonecpyInt: "",
      emailcpyDisplay: true,
      emailcpy: "",
    },
    themeId: "",
    searchkey: "",
    keywords: [],
    layoutId: TEMPLATE_MINIWEB_LIST[0].id, // Template of the web page - Basic is the default
    showroom: {
      jobName: "",
      description: "",
      priceRange: "",
      priceRangeDisplay: false,
      picturesLoop: false,
      score: "", // ratings
      background: {
        originalName: "",
        name: "",
        image: null,
      },
      displayPicture1: {
        originalName: "",
        name: "",
        image: null,
      },
      displayPicture2: {
        originalName: "",
        name: "",
        image: null,
      },
      displayPicture3: {
        originalName: "",
        name: "",
        image: null,
      },
    },
    services: {
      description: "",
      background: {
        originalName: "",
        name: "",
        image: null,
      },
    },
  };

  colorList = [
    { name: "Candy", hex: "#E4007A" },
    { name: "Jean", hex: "#2F6FAC" },
    { name: "Concrete", hex: "#3C3C3B" },
    { name: "Sunflower", hex: "#FFCE33" },
    { name: "Sea", hex: "#77C9C1" },
  ];

  // Variable to add a keyword to the keyword list.
  keyword = "";

  // Must be updated everytime the address is updated. It is forcing the recomputation of it.
  trickUpdateAddress = 0;
  // Must be updated everytime the picture is updated. It is forcing the recomputation of it.
  trickUpdatePicture = 0;
  // specific name for the image loaded from Miniweb
  imageNameComplement = FILEHEADERIMAGENAMECOMPLEMENTMINIWEB;
  imageNameComplementBackgroundShowroom =
    FILEHEADERIMAGENAMECOMPLEMENTMINIWEBBACGROUNDSHOWROOM;

  imageNameComplementInsideShowroom =
    FILEHEADERIMAGENAMECOMPLEMENTMINIWEBINSIDESHOWROOM;

  imageNameComplementBackgroundServices =
    FILEHEADERIMAGENAMECOMPLEMENTMINIWEBBACGROUNDSERVICES;

  // Dialog delete confirmation
  dialogDelete = false;
  // Template Management
  miniWebTemplateManager = new MiniWebTemplateManager();
  alignments = ["start", "center", "end"];

  qrcodeValue = "kidiwiservices.com";

  // Text Editor variables for element-tiptap
  extensions = [
    new Doc(),
    new Text(),
    new Paragraph(),
    new Heading({ level: 5 }),
    new Bold({ bubble: true }), // render command-button in bubble menu.
    new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
    new Italic({ bubble: true }),
    new Strike(),
    new FontType(),
    new FontSize(),
    new TextColor({ bubble: true }),
    new TextHighlight(),
    new ListItem(),
    new BulletList(),
    new OrderedList(),
    new Link(),
    new CodeBlock(),
    new Blockquote(),
    new TodoItem(),
    new TodoList(),
    new TextAlign(),
    new Indent(),
    new LineHeight(),
    new Table(),
    new TableHeader(),
    new TableCell(),
    new TableRow(),
    new FormatClear(),
    new Preview(),
  ];

  languageEditor = "fr"; // element-tiptap
  // editor's content
  content = "<h1>Heading</h1> <p>This Editor is awesome!</p>";

  @Watch("myServiceProvider.searchkey")
  myServiceProvider1(val, oldVal) {
    // console.log('myServiceProvider1')
    // console.log(val)
    // console.log(oldVal)
    if (val !== oldVal) {
      this.qrcodeValue = `${window.location.origin}/${this.$i18n.locale}/web/${this.myServiceProvider.searchkey}`;
      // console.log('value changed qrcode')
    }
  }

  async created() {
    await this.getMiniWeb();
    this.trickUpdateAddress++;
    this.trickUpdatePicture++;
    this.languageEditor = this.$i18n.locale;
  }

  internationalPhoneNumberFct(internationalPhoneNumber) {
    // console.log('Int Phone:', internationalPhoneNumber)
    this.myServiceProvider.contact.phonecpyInt = internationalPhoneNumber;
  }

  copyCpyData() {
    CgetMiniWebConfigFromSP().then((data) => {
      // console.log('data')
      // console.log(data)
      if (data.namecpy !== null && data.namecpy !== undefined)
        this.myServiceProvider.namecpy = data.namecpy;
      if (data.firstname !== null && data.firstname !== undefined)
        this.myServiceProvider.firstname = data.firstname;
      if (data.siret) this.myServiceProvider.siret = data.siret;

      if (data.addresscpy1 !== null && data.addresscpy1 !== undefined)
        this.myServiceProvider.addresscpy.address1 = data.addresscpy1;
      if (data.addresscpy2 !== null && data.addresscpy2 !== undefined)
        this.myServiceProvider.addresscpy.address2 = data.addresscpy2;
      if (data.postcodecpy !== null && data.postcodecpy !== undefined)
        this.myServiceProvider.addresscpy.postcode = data.postcodecpy;
      if (
        data.postcodecitycpy !== null &&
        data.postcodecitycpy !== undefined &&
        data.postcodecitycpy.id !== null &&
        data.postcodecitycpy.id !== undefined
      )
        this.myServiceProvider.addresscpy.cityId = data.postcodecitycpy.id;
      if (
        data.countrycpy !== null &&
        data.countrycpy !== undefined &&
        data.countrycpy.id !== null &&
        data.countrycpy.id !== undefined
      )
        this.myServiceProvider.addresscpy.countryId = data.countrycpy.id;

      if (data.emailcpy !== null && data.emailcpy !== undefined)
        this.myServiceProvider.contact.emailcpy = data.emailcpy;
      if (data.phonecpy !== null && data.phonecpy !== undefined) {
        this.myServiceProvider.contact.phonecpy = data.phonecpy;
        this.myServiceProvider.contact.phonecpyInt = data.phonecpy;
      }
      this.trickUpdateAddress++;
    });
  }

  addKeyword() {
    if (!this.keyword) return;
    this.myServiceProvider.keywords.push(this.keyword);
    this.keyword = "";
  }

  deleteKeyword(itemPosition) {
    this.myServiceProvider.keywords.splice(itemPosition, 1);
  }

  async updateMiniWebCompanyParameters() {
    // console.log('We are passing here in updateMiniWebCompanyParameters:')
    // console.log(DOMPurify.sanitize('<img src=x onerror=alert(1)//>'))
    // console.log(DOMPurify.sanitize('<svg><g/onload=alert(2)//<p>'))
    // console.log(DOMPurify.sanitize('<p>abc<iframe//src=jAva&Tab;script:alert(3)>def</p>'))
    // console.log('Before: ', this.myServiceProvider.showroom.description)
    // var cleanShowroomDescription = DOMPurify.sanitize(this.myServiceProvider.showroom.description)
    // console.log('After: ', cleanShowroomDescription)
    // var cleanServicesDescription = DOMPurify.sanitize(this.myServiceProvider.services.description)
    // console.log(cleanServicesDescription)
    // console.log('myServiceProvider:')
    // console.log(this.myServiceProvider)
    // We are checking if the search key contains some space.
    if (
      this.myServiceProvider.searchkey !==
        this.myServiceProvider.searchkey.replace(/\s+/g, "") ||
      this.myServiceProvider.searchkey !==
        this.myServiceProvider.searchkey.toLowerCase()
    ) {
      this.$root.kdwsnackbar.alert("miniweb.alertSpaceWebSiteName", -1);
      return;
    }
    // We are checking if the search key already exist (web site already exist?).
    if (this.myServiceProvider.searchkey) {
      const miniwebData = await getMiniWebConfig(
        this.myServiceProvider.searchkey.toLowerCase(),
      );
      // console.log('miniwebData')
      // console.log(miniwebData)
      // console.log(isObjectEmpty(miniwebData))
      // console.log(miniwebData.serviceProviderID === await getUserSub(), miniwebData.serviceProviderID, await getUserSub())
      if (
        !isObjectEmpty(miniwebData) &&
        miniwebData.serviceProviderID !== (await getUserSub())
      ) {
        this.$root.kdwsnackbar.alert("miniweb.alertDuplicateWebSiteName", -1);
        return;
      }
    }

    // We clean the HTML for XSS attack.
    this.myServiceProvider.showroom.description = DOMPurify.sanitize(
      this.myServiceProvider.showroom.description,
    );
    this.myServiceProvider.services.description = DOMPurify.sanitize(
      this.myServiceProvider.services.description,
    );

    // Then we build the data to be saved.
    const parameters = {
      // Identity parameters
      name: this.myServiceProvider.namecpy,
      firstname: this.myServiceProvider.firstname,
      siret: this.myServiceProvider.siret,
      toggleSiret: this.myServiceProvider.siretDisplay,
      address1: this.myServiceProvider.addresscpy.address1,
      address2: this.myServiceProvider.addresscpy.address2,
      postcode: this.myServiceProvider.addresscpy.postcode,
      miniWebPostcodecityId: this.myServiceProvider.addresscpy.cityId,
      miniWebCountryId: this.myServiceProvider.addresscpy.countryId,
      toggleAddress: this.myServiceProvider.addresscpyDisplay,
      email: this.myServiceProvider.contact.emailcpy,
      toggleEmail: this.myServiceProvider.contact.emailcpyDisplay,
      phone: this.myServiceProvider.contact.phonecpyInt,
      togglePhone: this.myServiceProvider.contact.phonecpyDisplay,
      // Nature of the Layout: Basic or Elegant
      layoutId: this.myServiceProvider.layoutId,
      // showroom parameters
      jobName: this.myServiceProvider.showroom.jobName,
      showroomDescription: this.myServiceProvider.showroom.description,
      priceRange: this.myServiceProvider.showroom.priceRange,
      togglePriceRange: this.myServiceProvider.showroom.priceRangeDisplay,
      score: this.myServiceProvider.showroom.score,
      showroomPicturesLoop: this.myServiceProvider.showroom.picturesLoop,

      // Services parameters
      servicesDescription: this.myServiceProvider.services.description,
      // to DO - store the keywords: this.myServiceProvider.keywords
      themeId: this.myServiceProvider.themeId,
      keywords: this.myServiceProvider.keywords.toString(),
    };
    // console.log('parameters:')
    // console.log(parameters)
    // We update the logo data associated
    var updateFileResult = await pictureUpdateS3(this.myServiceProvider.logo);
    // console.log('updateFileResult:')
    // console.log(updateFileResult)
    if (updateFileResult === "error") {
      this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
      return;
    }
    if (updateFileResult === "true") {
      parameters.logo = this.myServiceProvider.logo.name;
    }
    // we update the showroom background
    updateFileResult = await pictureUpdateS3(
      this.myServiceProvider.showroom.background,
    );
    // console.log('updateFileResult:')
    // console.log(updateFileResult)
    if (updateFileResult === "error") {
      this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
      return;
    }
    if (updateFileResult === "true") {
      parameters.showroomBackground =
        this.myServiceProvider.showroom.background.name;
    }
    var picturesArray = [];
    // we update the showroom pictures
    updateFileResult = await pictureUpdateS3(
      this.myServiceProvider.showroom.displayPicture1,
    );
    if (updateFileResult === "error") {
      this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
    }
    if (this.myServiceProvider.showroom.displayPicture1.name) {
      picturesArray.push(this.myServiceProvider.showroom.displayPicture1.name);
    }
    updateFileResult = await pictureUpdateS3(
      this.myServiceProvider.showroom.displayPicture2,
    );
    if (updateFileResult === "error") {
      this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
    }
    if (this.myServiceProvider.showroom.displayPicture2.name) {
      picturesArray.push(this.myServiceProvider.showroom.displayPicture2.name);
    }
    updateFileResult = await pictureUpdateS3(
      this.myServiceProvider.showroom.displayPicture3,
    );
    if (updateFileResult === "error") {
      this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
    }
    if (this.myServiceProvider.showroom.displayPicture3.name) {
      picturesArray.push(this.myServiceProvider.showroom.displayPicture3.name);
    }
    if (picturesArray.length > 0) {
      parameters.showroomPictures = picturesArray.toString();
    } else parameters.showroomPictures = "";

    // we update the services background
    updateFileResult = await pictureUpdateS3(
      this.myServiceProvider.services.background,
    );
    // console.log('updateFileResult:')
    // console.log(updateFileResult)
    if (updateFileResult === "error") {
      this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
      return;
    }
    if (updateFileResult === "true") {
      parameters.servicesBackground =
        this.myServiceProvider.services.background.name;
    }
    // We update the data in DB.
    CupdateMiniWebConfig(parameters)
      .then((miniweb) => {
        logger.info("MiniWeb updated.");
        this.$root.kdwsnackbar.alert("miniweb.alertUpdateSuccess");
        this.miniwebConfig = miniweb;
      })
      .catch((err) => {
        logger.error(err);
        this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
      });
    // We update the search key
    if (this.myServiceProvider.searchkey) {
      this.myServiceProvider.searchkey =
        this.myServiceProvider.searchkey.toLowerCase();
      CupdateSearchKey(this.myServiceProvider.searchkey)
        .then(() => {
          logger.info("MiniWeb updated.");
          this.$root.kdwsnackbar.alert("miniweb.alertUpdateSuccess");
        })
        .catch((err) => {
          logger.error(err);
          this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
        });
    }
  }

  async getMiniWeb() {
    try {
      this.displayMiniwebCreation = true;
      this.miniwebConfig = await CgetMiniWebConfig();
      // logger.info(`MiniWeb get ${JSON.stringify(this.miniwebConfig)}.`)
      if (
        this.miniwebConfig.data.getMiniWeb === null ||
        this.miniwebConfig.data.getMiniWeb === undefined
      ) {
        return;
      }
      const data = this.miniwebConfig.data.getMiniWeb;
      // console.log('data loaded:')
      // console.log(data)
      if (data) {
        //  We check if the site is not suspended.
        if (
          data.toggleMiniweb !== undefined &&
          data.toggleMiniweb !== null &&
          data.toggleMiniweb === false
        ) {
          console.log("Miniweb is suspended");
          return;
        }
        // are the data so we process them.
        // We proceed with the rest of the data
        if (data.name !== null && data.name !== undefined)
          this.myServiceProvider.namecpy = data.name;
        if (data.firstname !== null && data.firstname !== undefined)
          this.myServiceProvider.firstname = data.firstname;
        if (data.siret !== null && data.siret !== undefined)
          this.myServiceProvider.siret = data.siret;
        if (data.toggleSiret !== null && data.toggleSiret !== undefined)
          this.myServiceProvider.siretDisplay = data.toggleSiret;

        if (data.address1 !== null && data.address1 !== undefined)
          this.myServiceProvider.addresscpy.address1 = data.address1;
        if (data.address2 !== null && data.address2 !== undefined)
          this.myServiceProvider.addresscpy.address2 = data.address2;
        if (data.postcode !== null && data.postcode !== undefined)
          this.myServiceProvider.addresscpy.postcode = data.postcode;
        if (
          data.postcodecity !== null &&
          data.postcodecity !== undefined &&
          data.postcodecity.id !== null &&
          data.postcodecity.id !== undefined
        )
          this.myServiceProvider.addresscpy.cityId = data.postcodecity.id;
        if (
          data.country !== null &&
          data.country !== undefined &&
          data.country.id !== null &&
          data.country.id !== undefined
        )
          this.myServiceProvider.addresscpy.countryId = data.country.id;
        if (data.toggleAddress !== null && data.toggleAddress !== undefined)
          this.myServiceProvider.addresscpyDisplay = data.toggleAddress;
        if (data.email !== null && data.email !== undefined)
          this.myServiceProvider.contact.emailcpy = data.email;
        if (data.toggleEmail !== null && data.toggleEmail !== undefined)
          this.myServiceProvider.contact.emailcpyDisplay = data.toggleEmail;
        if (data.phone !== null && data.phone !== undefined)
          this.myServiceProvider.contact.phonecpy = data.phone;
        if (data.togglePhone !== null && data.togglePhone !== undefined)
          this.myServiceProvider.contact.phonecpyDisplay = data.togglePhone;
        if (data.logo !== null && data.logo !== undefined) {
          // this.myServiceProvider.logoOriginalName = data.logo
          this.myServiceProvider.logo.originalName = data.logo;
          this.myServiceProvider.logo.name = data.logo;
        }
        if (data.searchkey !== null && data.searchkey !== undefined) {
          this.myServiceProvider.searchkey = data.searchkey;
          this.qrcodeValue = `${window.location.origin}/${this.$i18n.locale}/web/${this.myServiceProvider.searchkey}`;
        }
        if (data.themeId !== null && data.themeId !== undefined)
          this.myServiceProvider.themeId = data.themeId;
        if (data.owner !== null && data.owner !== undefined)
          this.myServiceProvider.id = data.owner;
        if (data.layoutId !== null && data.layoutId !== undefined)
          this.myServiceProvider.layoutId = data.layoutId;
        this.miniWebTemplateManager.selectByID(this.myServiceProvider.layoutId);
        // keywords: [], array to rebuild
        if (data.keywords) {
          this.myServiceProvider.keywords = data.keywords.split(",");
        }

        // Showroom data:
        if (data.priceRange !== null && data.priceRange !== undefined)
          this.myServiceProvider.showroom.priceRange = data.priceRange;
        if (
          data.togglePriceRange !== null &&
          data.togglePriceRange !== undefined
        )
          this.myServiceProvider.showroom.priceRangeDisplay =
            data.togglePriceRange;
        if (
          data.showroomDescription !== null &&
          data.showroomDescription !== undefined
        )
          this.myServiceProvider.showroom.description =
            data.showroomDescription;
        if (data.jobName !== null && data.jobName !== undefined)
          this.myServiceProvider.showroom.jobName = data.jobName;
        if (data.score !== null && data.score !== undefined)
          this.myServiceProvider.showroom.score = data.score;
        if (
          data.showroomBackground !== null &&
          data.showroomBackground !== undefined
        ) {
          // this.myServiceProvider.showroom.showroomBackgroundOriginalName = data.showroomBackground
          this.myServiceProvider.showroom.background.originalName =
            data.showroomBackground;
          this.myServiceProvider.showroom.background.name =
            data.showroomBackground;
        }
        if (
          data.showroomPictures !== null &&
          data.showroomPictures !== undefined
        ) {
          if (data.showroomPictures !== "") {
            // we build a table of pictures
            const pictureArray = data.showroomPictures.split(",");
            if (pictureArray.length > 0) {
              // this.myServiceProvider.showroom.displayPicture1OriginalName = pictureArray[0]
              this.myServiceProvider.showroom.displayPicture1.originalName =
                pictureArray[0];
              this.myServiceProvider.showroom.displayPicture1.name =
                pictureArray[0];
              if (pictureArray.length > 1) {
                // this.myServiceProvider.showroom.displayPicture2OriginalName = pictureArray[1]
                this.myServiceProvider.showroom.displayPicture2.originalName =
                  pictureArray[1];
                this.myServiceProvider.showroom.displayPicture2.name =
                  pictureArray[1];
              }
              if (pictureArray.length > 2) {
                // this.myServiceProvider.showroom.displayPicture3OriginalName = pictureArray[2]
                this.myServiceProvider.showroom.displayPicture3.originalName =
                  pictureArray[2];
                this.myServiceProvider.showroom.displayPicture3.name =
                  pictureArray[2];
              }
            }
          }
        }
        if (
          data.showroomPicturesLoop !== null &&
          data.showroomPicturesLoop !== undefined
        )
          this.myServiceProvider.showroom.picturesLoop =
            data.showroomPicturesLoop;
        // Services data:
        if (
          data.servicesDescription !== null &&
          data.servicesDescription !== undefined
        )
          this.myServiceProvider.services.description =
            data.servicesDescription;
        if (
          data.servicesBackground !== null &&
          data.servicesBackground !== undefined
        ) {
          // this.myServiceProvider.services.servicesBackgroundOriginalName = data.servicesBackground
          this.myServiceProvider.services.background.originalName =
            data.servicesBackground;
          this.myServiceProvider.services.background.name =
            data.servicesBackground;
        }
        // console.log('this.myServiceProvider:')
        // console.log(this.myServiceProvider)
        this.displayMiniwebCreation = false;
      }
    } catch (err) {
      logger.error(err);
      this.$root.kdwsnackbar.alert("miniweb.alertSPFailedLoad", -1);
    }
  }

  async createMiniWeb() {
    this.miniwebConfig = await CgetMiniWebConfig();
    // logger.info(`MiniWeb get ${JSON.stringify(this.miniwebConfig)}.`)
    if (
      this.miniwebConfig.data.getMiniWeb === null ||
      this.miniwebConfig.data.getMiniWeb === undefined
    ) {
      CcreateMiniWeb()
        .then(() => {
          logger.info("MiniWeb created.");
          this.$root.kdwsnackbar.alert("miniweb.alertCreationSuccess");
          this.displayMiniwebCreation = false;
          // We load the default data after creation
          this.getMiniWeb().then(() => {
            this.trickUpdateAddress++;
            this.trickUpdatePicture++;
          });
        })
        .catch((err) => {
          logger.error(err);
          this.$root.kdwsnackbar.alert("miniweb.alertCreationFailed", -1);
        });
    } else {
      const data = this.miniwebConfig.data.getMiniWeb;
      // We check if the data have been suspended in the past.
      if (
        data.toggleMiniweb !== undefined &&
        data.toggleMiniweb !== null &&
        data.toggleMiniweb === false
      ) {
        const parameters = {
          toggleMiniweb: true,
        };
        // We update the data in DB, especially the toggleMiniweb as we already have some data.
        CupdateMiniWebConfig(parameters)
          .then(() => {
            logger.info("MiniWeb restaured.");
            // We are planning to clean the search ID in which we put the SPID without '-'.
            const SPID = this.myServiceProvider.id.replaceAll("-", "");
            if (SPID === this.myServiceProvider.searchkey) {
              this.myServiceProvider.searchkey = "";
            }
            this.displayMiniwebCreation = false;
          })
          .catch((err) => {
            logger.error(err);
            this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
          });
      }
    }
  }

  deleteMiniWeb() {
    // We open the dialog box for confirmation.
    this.dialogDelete = true;
  }

  async deleteMiniWebConfirmed(event) {
    if (event && event.type === "Delete" && event.result === "OK") {
      // We delete the S3 pictures
      await deletePictureS3(this.myServiceProvider.logo);
      await deletePictureS3(this.myServiceProvider.showroom.background);
      await deletePictureS3(this.myServiceProvider.showroom.displayPicture1);
      await deletePictureS3(this.myServiceProvider.showroom.displayPicture2);
      await deletePictureS3(this.myServiceProvider.showroom.displayPicture3);
      await deletePictureS3(this.myServiceProvider.services.background);
      const parameters = {
        logo: "",
        showroomBackground: "",
        showroomPictures: "",
        servicesBackground: "",
        toggleMiniweb: false,
      };

      // We erase the search key and we put the SPID to release the search key ''
      const SPID = this.myServiceProvider.id.replaceAll("-", "");
      this.qrcodeValue = "kidiwiservices.com";
      CupdateSearchKey(SPID)
        .then(() => {
          logger.info("Search key replaced with SP UUID.");
        })
        .catch((err) => {
          logger.error(err);
          this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
        });

      // We update the data in DB, especially the toggleLiniweb and we delete the pictures references.
      CupdateMiniWebConfig(parameters)
        .then(() => {
          logger.info("MiniWeb deleted.");
          // this.$root.kdwsnackbar.alert('miniweb.alertUpdateSuccess')
          // this.miniwebConfig = miniweb
          this.displayMiniwebCreation = true;
        })
        .catch((err) => {
          logger.error(err);
          this.$root.kdwsnackbar.alert("miniweb.alertUpdateFailed", -1);
        });
    }
  }

  templateChange() {
    // console.log('Template changed', this.myServiceProvider.layoutId)
    this.miniWebTemplateManager.selectByID(this.myServiceProvider.layoutId);
  }
}

export default WebAdmin;
</script>
